<!-- 提交成功 -->
<template>
    <div>
        <!-- <van-nav-bar title="提交成功" left-text="返回" left-arrow @click-left="onClickLeft" /> -->
        <!-- <div class='SubSuccess'>
            <van-icon name="checked" />
            <div class="text">
                {{text}}
            </div>
            <div class="default_text">
                请等待审批结果
            </div>
        </div> -->
        <div class="news_wenan">
            <div class="news_wenan_img"><img class="news_bg_imh" src="../../assets/img/jiezhisuccess.png" alt=""></div>
        </div>
       <div class="news_wenan_text">
            <div class="news_wenan_text_on">{{ type === 'entry_success' ? text : text + '，请等待审批！'}}</div>
        </div>
        <div class="bottom_back_fiexd">
            <div class="bottom_back_on" @click="gobanck">
                <div class="bottom_back_wenz">返回主页</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
    </div>

</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            text: "离职申请已提交",
            type: ''
        };
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.$store.commit("getWxSDK");
        this.$store.commit("hideTabbar");
        this.type = this.$route.query.type
        if (this.$route.query.type == 'ApplyMoney') {
            this.text = "借支申请已提交"
        } else if (this.$route.query.type == 'entry') {
            this.text = "入职申请已提交"
        }else if(this.$route.query.type == 'entry_success'){
            this.text = "入职成功!"
        }
    },
    methods: {
        gobanck(){
            this.$router.replace('/home')
        },
        onClickLeft() {
            this.$router.go(-1)
        },
    }
}
</script>
<style  scoped>
/* @import url(); 引入公共css类 */
.SubSuccess {
    text-align: center;
    padding-top: 300px;
    box-sizing: border-box;
    color: #666666;
}
.SubSuccess >>> .van-icon {
    font-size: 90px;
    color: #4c94f6;
}
.text {
    font-size: 28px;
    margin-top: 10px;
}
.default_text {
    font-size: 24px;
    margin-top: 15px;
}
</style>